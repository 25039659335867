import { HTTP_METHODS, IPagination } from './common.types';
import { PaymentMethod } from './rates.types';

export interface HotelContactItem {
  id: number;
  value: string;
  icon: string | null;
  type: string;
  /**
   * true если пришло с 2ГИС
   */
  is_external: boolean;
  internal_id: string | null;
  is_admin: boolean;
  is_active: boolean;
  is_notifications_allowed: boolean;
  updated: string | null;
  hotel_user: boolean;
}

export type HotelBookingNotificationContactItem = HotelContactItem;

export type OrganizationTypes =
  | 'legal_entity'
  | 'self_employed'
  | 'individual_entrepreneur';

export interface LegalInfo {
  id?: number;
  organization_name: string;
  // Юридический адрес
  legal_address: string;
  inn: string;
  kpp: string;
  ogrn: string;
  // Расчётный счёт
  checking_account: string;
  // Кор. счёт
  correspondent_account: string;
  bik: string;
  // Ставка налогообложения
  taxation_perc: number | null;
  bank_name: string;
  consent_dt: boolean;
  modified: string | null;
  created: string | null;
  organization_type: OrganizationTypes;
}

export interface HotelInfoResponse {
  id: number;

  title_block: {
    hotel_name: string;
    address: string;
    /**
     * Количество звезд отеля
     */
    star_rating: number;
    main_photo: string;

    /**
     * Ссылка на отель в Отелло, если null - значит не опубликован
     */
    otello_url: string | null;

    /**
     * Статус отеля в ЛК (заблокирован или активен)
     */
    is_active: boolean;
  };

  photos: {
    id: number;
    is_main: boolean;
    urls: {
      '100x100': string;
      '300x300': string;
      '600x600': string;
      main: string;
    };
  }[];

  legal_info: LegalInfo | null;

  is_extranet: boolean;

  accommodation_rules: {
    reception_from: string | null;
    reception_to: string | null;
    check_in_time_after: string | null;
    check_out_time_after: string | null;
    is_reception_all_time: boolean;
    children_min_age: number | null;
    children_ages: {
      id: number;
      interval_from: number;
      interval_to: number;
    }[];
  };

  booking_notification_contacts: HotelBookingNotificationContactItem[];

  hotel_contacts: {
    contact_details: HotelContactItem[];
    representatives_contacts: HotelContactItem[];
    social_networks: HotelContactItem[];
  };

  amenity_categories: {
    id: number;
    name: string;
    order: number;
    amenities: {
      id: number;
      name: string;
      icon: string | null;
      order: number;
    }[];
  }[];
}

export interface HotelLegalInfoQueryParams {
  hotelId: number;
  legalId?: number;
  method: HTTP_METHODS;
  body:
    | HotelInfoResponse['legal_info']
    | Omit<HotelInfoResponse['legal_info'], 'id'>;
}

export enum BookingContactsTypes {
  email = 'email',
  telegram = 'telegram',
}

export interface HotelBookingContactsQueryParams {
  id: number;
  booking_notification_contacts: {
    value: string;
    type: BookingContactsTypes;
    is_admin?: boolean;
  }[];
}

export interface HotelContactsQueryParams {
  id: number;
  hotel_contacts: { type: string; value: string }[];
}

export interface HotelCheckInRulesQueryParams {
  id: number;
  body: {
    reception_from: string | null;
    reception_to: string | null;
    check_in_time_after: string | null;
    check_out_time_after: string | null;
    is_reception_all_time: boolean;
    children_min_age: number | null;
    children_ages?: {
      id: number;
      interval_from: number;
      interval_to: number;
    }[];
  };
}

export interface HotelListItem {
  id: number;
  hotel_name: string;
  /**
   * Количество тарифов
   */
  rates: number;
  /**
   * Количество категорий номера
   */
  room_types: number;
  otello_id: number | null;
  address: string;
  organization_type?: OrganizationTypes;
  channel_manager: string | null;
  channel_manager_hotel_id: string | null;
}

export type TransformedHotelListItem = {
  id: number;
  title: string;
  subtitle?: string;
  address: string;
  amount: {
    roomTypes: number;
    rates: number;
  };
  otelloId: number | null;
  channel_manager: string | null;
  channel_manager_hotel_id: string | null;
  is_extranet: boolean;
};

export interface HotelListResponse extends IPagination {
  results: HotelListItem[];
}

export interface HotelListQueryParams {
  search?: string | null;
  branch_id?: string | null;
}

export interface HotelAllowPublishResponse {
  main_flags: {
    is_allow_publish: boolean;
    error_keys: {
      is_quota: boolean;
      is_prices: boolean;
      is_legal_info: boolean;
      is_admin_email_existed: boolean;
    };
  };
  notification_flags: {
    is_show_notification: boolean;
    notification_keys: {
      is_need_update_payment_method: boolean;
    };
  };
}

export interface HotelPublishQueryParams {
  id: number;
  body: {
    publish: boolean;
    actions: {
      bulk_update: {
        rate?: { payment_method: PaymentMethod };
      } | null;
      bulk_create: null;
      bulk_delete: null;
    };
  };
}

export interface HotelPublishResponse {
  is_registered: boolean;
}

export interface HotelSearchLegalInfoQueryParams {
  inn: string;
  id: string;
}

export interface HotelSearchLegalInfoResponse {
  is_active: boolean;
  organization_name: string;
  kpp: string;
  inn: string;
  ogrn: string;
  legal_address: string;
}

export interface LegalInfoChangesQueryParams {
  hotelId: number;
  body: {
    inn: string;
    kpp: string | null;
    ogrn: string;
    requested_dt: string | null;
    consent_dt: boolean;
  };
}

export enum UnpublishedRecordsErrorCodes {
  failed = 'failed',
  failed_sent = 'failed_sent',
  http_status_not_ok = 'http_status_not_ok',
  rejected = 'rejected',
  unknown = 'unknown',
}

export enum UnpublishedRecordsPositiveCodes {
  accepted = 'accepted',
  sent = 'sent',
}

export interface HasUnpublishedRecordsResponse {
  has_unpublished_records: boolean;
  code: UnpublishedRecordsErrorCodes | UnpublishedRecordsPositiveCodes;
  message: string;
}
