import { useEffect } from 'react';

import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import loadable from '@loadable/component';
import { NotFound } from '@otello/features';
import { pages } from '@otello/helpers';
import { PublicLayout, ProtectedLayout } from '@otello/layouts';
import { ErrorBlock } from '@otello/ui';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

const LoginPages = loadable.lib(() => import('@otello/pages/login'));
const MainPages = loadable.lib(() => import('@otello/pages/main'));
const RoomsPages = loadable.lib(() => import('@otello/pages/rooms'));
const RatesPages = loadable.lib(() => import('@otello/pages/rates'));
const BookingPages = loadable.lib(() => import('@otello/pages/booking'));
const ProfilePages = loadable.lib(() => import('@otello/pages/profile'));
const PricesPages = loadable.lib(() => import('@otello/pages/prices'));
const DigestPage = loadable.lib(() => import('@otello/pages/digest'));
const DiscountPages = loadable.lib(() => import('@otello/pages/discount'));
const UnavailablePage = loadable.lib(() => import('@otello/pages/unavailable'));
const FinancePage = loadable.lib(() => import('@otello/pages/finance'));

if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),

      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

/** Страницы, доступные не авторизированным пользователям */
const publicPages: { path: string; Component: EmotionJSX.Element }[] = [
  {
    path: pages.LOGIN,
    Component: <LoginPages>{({ default: { Login } }) => <Login />}</LoginPages>,
  },
  {
    path: pages.REGISTRATION,
    Component: (
      <LoginPages>
        {({ default: { RegistrationForm } }) => <RegistrationForm />}
      </LoginPages>
    ),
  },
  {
    path: pages.RESET,
    Component: <LoginPages>{({ default: { Reset } }) => <Reset />}</LoginPages>,
  },
];

/** Страницы, доступные только после входа */
const protectedPages: { path: string; Component: EmotionJSX.Element }[] = [
  {
    path: pages.MAIN,
    Component: <MainPages>{({ default: { Main } }) => <Main />}</MainPages>,
  },
  {
    path: pages.PROFILE,
    Component: (
      <ProfilePages>{({ default: { Profile } }) => <Profile />}</ProfilePages>
    ),
  },
  {
    path: pages.ROOMS,
    Component: (
      <RoomsPages>{({ default: { RoomsList } }) => <RoomsList />}</RoomsPages>
    ),
  },
  {
    path: pages.ROOMS_VIEW,
    Component: (
      <RoomsPages>{({ default: { RoomsView } }) => <RoomsView />}</RoomsPages>
    ),
  },
  {
    path: pages.ROOMS_CREATE,
    Component: (
      <RoomsPages>
        {({ default: { RoomsCreate } }) => <RoomsCreate />}
      </RoomsPages>
    ),
  },
  {
    path: pages.RATES,
    Component: (
      <RatesPages>{({ default: { RatesList } }) => <RatesList />}</RatesPages>
    ),
  },
  {
    path: pages.RATES_CREATE,
    Component: (
      <RatesPages>
        {({ default: { RatesCreate } }) => <RatesCreate />}
      </RatesPages>
    ),
  },
  {
    path: pages.RATES_EDIT,
    Component: (
      <RatesPages>{({ default: { RatesEdit } }) => <RatesEdit />}</RatesPages>
    ),
  },
  {
    path: pages.PRICES,
    Component: (
      <PricesPages>{({ default: { Prices } }) => <Prices />}</PricesPages>
    ),
  },

  {
    path: pages.DISCOUNT,
    Component: (
      <DiscountPages>
        {({ default: { DiscountList } }) => <DiscountList />}
      </DiscountPages>
    ),
  },
  {
    path: pages.DISCOUNT_CREATE,
    Component: (
      <DiscountPages>
        {({ default: { DiscountCreate } }) => <DiscountCreate />}
      </DiscountPages>
    ),
  },
  {
    path: pages.PRICES_DISCOUNT,
    Component: (
      <DiscountPages>
        {({ default: { DiscountList } }) => <DiscountList />}
      </DiscountPages>
    ),
  },
  {
    path: pages.PRICES_DISCOUNT_CREATE,
    Component: (
      <DiscountPages>
        {({ default: { DiscountCreate } }) => <DiscountCreate />}
      </DiscountPages>
    ),
  },
  {
    path: pages.BOOKING,
    Component: (
      <BookingPages>
        {({ default: { BookingList } }) => <BookingList />}
      </BookingPages>
    ),
  },
  {
    path: pages.DIGEST,
    Component: (
      <DigestPage>{({ default: { Digest } }) => <Digest />}</DigestPage>
    ),
  },
  {
    path: pages.FINANCE,
    Component: (
      <FinancePage>{({ default: { Finance } }) => <Finance />}</FinancePage>
    ),
  },
];

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<PublicLayout />}>
        {publicPages.map(({ path, Component }) => (
          <Route
            key={path}
            path={path}
            element={Component}
            errorElement={<ErrorBlock />}
          />
        ))}
      </Route>

      <Route element={<ProtectedLayout />}>
        {protectedPages.map(({ path, Component }) => (
          <Route
            key={path}
            path={path}
            element={Component}
            errorElement={<ErrorBlock />}
          />
        ))}
        <Route path="*" element={<NotFound />} />
      </Route>

      <Route
        path={pages.UNAVAILABLE}
        element={
          <UnavailablePage>
            {({ default: { Unavailable } }) => <Unavailable />}
          </UnavailablePage>
        }
        errorElement={<ErrorBlock />}
      />
    </Route>,
  ),
);
