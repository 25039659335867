import { FC, useMemo } from 'react';

import {
  Box,
  Grid,
  ListItemIcon,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { getBasePath, rem } from '@otello/helpers';
import { useAppSelector } from '@otello/store';
import { themePalette } from '@otello/theme';
import { NavLink } from 'react-router-dom';

import { NavLinkBase } from '../../Header.styles';
import { navRouter } from '../../helpers/helpers';
import { NavigationMore } from '../NavigationMore/NavigationMore';

export const NavigationMobile: FC = () => {
  const isViewFullMenu = useMediaQuery('(min-width: 500px)');
  const active = useAppSelector((state) => state.hotel.active);

  const isExtranet = !!active?.is_extranet;

  const mainMenuItems = navRouter({
    isMobile: true,
    isExtranet: !!active?.is_extranet,
  }).slice(0, isExtranet ? 4 : 5);

  const restMenuItems = navRouter({
    isMobile: true,
    isExtranet: !!active?.is_extranet,
  }).slice(4);

  const MenuItems = useMemo(() => {
    return (
      isViewFullMenu ? navRouter({ isMobile: true, isExtranet }) : mainMenuItems
    ).map(({ path, name, icon, loadablePages }) => (
      <Grid item key={path} flexDirection="column">
        <NavLinkBase
          data-cy={`${getBasePath(path)}_link`}
          component={NavLink}
          disableTouchRipple
          to={path}
          onMouseOver={() => {
            loadablePages.preload();
          }}
          sx={{
            padding: 0,

            '&:hover': {
              background: 'unset',
            },

            '& .MuiListItemIcon-root': {
              marginRight: 'unset',
            },

            '&.active': {
              background: 'unset',

              '& .MuiTypography-root': {
                color: 'basic.primary',
              },
            },
          }}
        >
          <Box
            width={rem(64)}
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={rem(8)}
          >
            <ListItemIcon>{icon}</ListItemIcon>

            <Typography
              variant="p5"
              maxWidth={rem(60)}
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {name}
            </Typography>
          </Box>
        </NavLinkBase>
      </Grid>
    ));
  }, [isViewFullMenu, isExtranet]);

  if (!isViewFullMenu && isExtranet) {
    return (
      <Grid
        component="nav"
        container
        height="100%"
        justifyContent="space-evenly"
        alignItems="start"
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          height: rem(60),
          backgroundColor: 'surface.primary',
          borderTop: `1px solid ${themePalette.bg.primary}`,
          padding: rem(6, 0, 0),
        }}
      >
        {MenuItems}

        <NavigationMore menu={restMenuItems} />
      </Grid>
    );
  }

  return (
    <Grid
      component="nav"
      container
      height="100%"
      justifyContent="space-evenly"
      alignItems="start"
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10,
        height: rem(60),
        backgroundColor: 'surface.primary',
        borderTop: `1px solid ${themePalette.bg.primary}`,
        padding: rem(6, 0, 0),
      }}
    >
      {MenuItems}
    </Grid>
  );
};
